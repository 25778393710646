<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="KeyIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Modules.User.Label.Change password') }}
      </h4>
    </div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
      >
      <!-- Form: Password Form -->
      <b-form class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: New password -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Modules.User.Label.New password')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Modules.User.Label.Password')"
                vid="password"
                rules="required|min:8|regex:^(?=.*?[a-zA-Z])(?=.*?[0-9]).*$"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="userPassword.password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    :placeholder="$t('Modules.User.Label.New password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Confirm password -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Modules.User.Label.Confirm password')"
              label-for="confirm_password"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Modules.User.Label.Confirm password field')"
                vid="confirm_password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirm_password"
                    v-model="userPassword.confirm_password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="confirm_password"
                    :placeholder="$t('Modules.User.Label.Confirm password')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="reset"
            >
              {{ $t('Cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from '@vue/composition-api'
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import formValidation from '@core/comp-functions/forms/form-validation'
import UserRepository from '@/modules/user/repository/userRepository'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const blankUserPassword = {
      id: props.userData.id,
      password: '',
      confirm_password: ''
    }
    const userPassword = ref(JSON.parse(JSON.stringify(blankUserPassword)))
    const resetuserData = () => {
      userPassword.value = JSON.parse(JSON.stringify(blankUserPassword))
    }

    const onSubmit = () => {
      UserRepository.changePassword(userPassword.value)
        .then(() => {
          resetForm()
        })
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userPassword,
      onSubmit,

      refFormObserver,
      resetForm,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
