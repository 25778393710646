<template>
  <div :key="whitelistKey">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="AirplayIcon"
        size="19"
      />
      <h4 class="mb-1 ml-50">
        {{ $t('Modules.User.Label.Whitelist') }}
      </h4>
    </div>
    <b-row>
      <b-col cols="12" md="6" lg="4">Browser ID</b-col>
      <b-col cols="12" md="6" lg="4">{{ $t('Modules.User.Label.Last Used') }}</b-col>
      <b-col cols="12" md="6" lg="4">{{ $t('Actions') }}</b-col>
    </b-row>
    <b-row v-for="(item, index) in whitelist" :key="item.id" class="mt-1">
      <b-col cols="12" md="6" lg="4">
        <b-input-group
          class="input-group-merge"
          :class="true ? 'is-invalid' : null"
        >
          <span v-if="!item.edit">{{ item.browserId }}</span>
          <b-form-input
            v-else
            v-model="item.browserId"
            :state="!item.browserId ? false : null"
            class="form-control-merge"
            placeholder="Browser ID"
          />
        </b-input-group>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <b-input-group
          class="input-group-merge"
        >
          <span v-html="(item.lastUsed ? formatTimeZoneOneLine(item.lastUsed) : '')"></span>
        </b-input-group>
      </b-col>
      <b-col cols="12" md="6" lg="4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          class="btn-icon mr-1"
          @click="edit(item)"
          v-if="!item.edit"
        >
          <feather-icon icon="EditIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="btn-icon mr-1"
          @click="item.id ? update(item) : save(item)"
          v-else
        >
          <feather-icon icon="SaveIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="btn-icon"
          @click="remove(item, index)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="add"
        >
          {{ $t('Add') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from '@vue/composition-api'
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import formValidation from '@core/comp-functions/forms/form-validation'
import UserRepository from '@/modules/user/repository/userRepository'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const vm = context.root
    const whitelistKey = ref(vm.generateSyncId())
    const whitelist = ref(JSON.parse(JSON.stringify(props.userData.whitelist)))
    const add = () => {
      whitelist.value.push({
        browserId: null,
        lastUsed: null,
        edit: true
      })
    }
    const save = (device) => {
      if(device.browserId) {
        UserRepository.createWhitelist({userId: props.userData.id, browserId: device.browserId})
        .then((response) => {
          device.id = response.data.data.device.id
          device.browserId = response.data.data.device.browserId
          device.lastUsed = response.data.data.device.lastUsed
          device.edit = false
          whitelistKey.value = vm.generateSyncId()
        })
      }
    }
    const edit = (device) => {
      device.edit = true
      whitelistKey.value = vm.generateSyncId()
    }
    const update = (device) => {
      if(device.browserId) {
        UserRepository.updateWhitelist({id: device.id, browserId: device.browserId})
        .then((response) => {
          device.id = response.data.data.device.id
          device.browserId = response.data.data.device.browserId
          device.lastUsed = response.data.data.device.lastUsed
          device.edit = false
          whitelistKey.value = vm.generateSyncId()
        })
      }
    }
    const remove = async (device, index) => {
      if(await vm.showDeleteConfirmDialog()) {
        UserRepository.deleteWhitelist(device.id)
        .then(() => {
          whitelist.value.splice(index, 1)
        })
      }
    }
    return {
      add, save, edit, update, remove,
      whitelistKey,
      whitelist
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
